<template>
    <div class="storeList">
        <div class="flex-row tab-box">
            <div @click="tabItemClick(index)" class=" tabItem" :class="tabIndex==index?'tab-select':''" v-for="(item, index) in stateArr" :key="index">{{item.title}}
            </div>
        </div>

        <div class="storeItem" v-for="(item, index) in dataArr" :key="index">
            <div class="top1">
                <div class="right">
                    <div class="title"><span class="tip1">领取人：</span>{{ item.mobile }}</div>
                    <div class="title"><span class="tip1">领取时间：</span>{{ xxparseTime(item.createTime)}}</div>
                    <div class="title" v-if="item.writeOffUser"><span class="tip1">核销人：</span>{{ item.writeOffUser }}
                    </div>
                    <div class="title" v-if="item.writeOffUser"><span class="tip1">核销时间：</span>{{
                    xxparseTime(item.writeOffTime) }}</div>

                </div>
            </div>

        </div>


    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    data() {
        return {
            dataArr: [],
            tabIndex:0,
            stateArr: [{ title: '待核销', status: 0 }, { title: '已核销', status: 1 }, { title: '已作废', status :2}],
            quarmas: {
                pageNum: 1,
                pageSize: 10,
                adcId: null,
                volumeCode: null,
                status:null
            },
            beginTime: "开始时间",
            endTime: "结束时间",
            time: true,
            total: 0,

        };
    },
    created() {
        this.quarmas.adcId = this.$route.query.storeAdcId
        this.quarmas.volumeCode = this.$route.query.volumeCode
    },
    mounted() {
        this.requestData();
    },
    methods: {

        addpage() {
            this.copy = true;
            this.quarmas.pageNum = this.quarmas.pageNum + 1;
            this.init();
        },
        isBeforeTime(item) {
            if (!item.endTime) {
                return true
            }
            if (item.endTime <= new Date()) {
                return false
            }
            return true
        },
        tabItemClick(index) {
            this.tabIndex = index;
            this.quarmas.pageNum = 1;
            this.requestData()
        },
        getStatusStr(state) {
            if (state == 0) {
                return '已启用';
            }
            if (state == 1) {
                return '已禁用';
            }
            //2
            return '已删除'
        },
        requestData() {
            this.quarmas.status = this.stateArr[this.tabIndex].status
            this.$http.querycouponuser(this.quarmas).then((res) => {
                if (res.code == 200) {
                    this.dataArr = res.data
                }
            });
        },

    },
};
</script>
<style lang="scss" scoped>
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.storeList {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #f8f8f8;
    .tab-box{
        width: 100%;
        height: 80rpx;
        justify-content: space-around;
        background: white;
        height: 40px;
        .tabItem{
            height: 30px;
            line-height: 30px;
        }
        .tab-select{
            border-bottom: 2px solid rgba(21, 123, 250, 1);
        }
    }

    .storeItem {
        margin: 0 auto;
        margin-top: 15px;
        width: 345px;
        background: white;
        border-radius: 8px;
        padding: 12px 12px;
        box-sizing: border-box;

        .top1 {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .right {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }


        .title {
            font-size: 14px;
            line-height: 30px;
        }



    }

  
}

</style>